import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { combineJobsAndPoints, parseFile } from '../../../helpers';
import { setJobs } from '../../../features/jobsSlice';

import InputFile from '../../../components/InputFile';
import Button from '../../../components/Button';
import Tag from '../../../components/Tag';

const FileSelect = () => {
  const [rawJobs, setRawJobs] = useState(null);
  const [rawPoints, setRawPoints] = useState(null);
  const [missingJobFields, setMissingJobFields] = useState([]);
  const [missingPointFields, setMissingPointFields] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const findMissingJobFields = (fileFields) => {
    const requiredFields = [
      // 'jobType', // defaults to Soil
      // 'jobSubType', // defaults to Carbon Project
      'clientReference',
      'field.location.longitude',
      'field.location.latitude',
      'field.boundary',
    ];
    const missingFields = requiredFields.filter((x) => !fileFields.includes(x));
    setMissingJobFields(missingFields);

    return missingFields.length === 0;
  };

  const findMissingPointFields = (fileFields) => {
    const requiredFields = [
      'jobClientReference',
      'clientReference',
      'pointLocation.latitude',
      'pointLocation.longitude',
      'type',
    ];
    const missingFields = requiredFields.filter((x) => !fileFields.includes(x));
    setMissingPointFields(missingFields);

    return missingFields.length === 0;
  };

  const handleNext = () => {
    dispatch(setJobs(combineJobsAndPoints(rawJobs, rawPoints)));
    navigate('/steps/preview');
  };

  const parseJobs = async (file) => {
    setRawJobs(null);
    const { records, fields } = await parseFile(file);
    if (findMissingJobFields(fields)) {
      setRawJobs(records);
    }
  };

  const parsePoints = async (file) => {
    setRawPoints(null);
    const { records, fields } = await parseFile(file);
    if (findMissingPointFields(fields)) {
      setRawPoints(records);
    }
  };

  return (
    <>
      <section className="flex-1 mx-5 my-6">
        <div className="flex mx-4 my-8 flex-wrap justify-center gap-32 ">
          <div className="flex-1">
            <InputFile label="Jobs CSV File" handleData={parseJobs} />
            {rawJobs ? <Tag>{rawJobs.length} jobs found</Tag> : null}
            {missingJobFields.length ? (
              <div className="text-red-500 text-sm">
                Required Fields Missing:{' '}
                <span className="font-bold">{missingJobFields.join(', ')}</span>
              </div>
            ) : null}
          </div>
          <div className="flex-1">
            <InputFile label="Points CSV File" handleData={parsePoints} />
            {rawPoints ? <Tag>{rawPoints?.length} points found</Tag> : null}
            {missingPointFields.length ? (
              <div className="text-red-500 text-sm">
                Required Fields Missing:{' '}
                <span className="font-bold">{missingPointFields.join(', ')}</span>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      <footer className="flex justify-between">
        <a
          href="https://docs.deveronapp.com/docs/csv-data-upload"
          className="bg-blue-500 hover:bg-blue-700 text-white hover:text-white font-bold py-2 px-4 rounded"
          target="_blank"
          rel="noreferrer"
        >
          Read the Docs
        </a>

        <Button disabled={!rawJobs?.length} handleClick={handleNext}>
          Next
        </Button>
      </footer>
    </>
  );
};

export default FileSelect;
